<template>
  <div class="enterpriseList">
    <div class="bread"><i class="icon el-icon-s-unfold" />企业信息</div>
    <!-- <div class="search">
      <el-form ref="dataForm" :model="dataForm" label-width="60px">
        <el-form-item label="职位：" class="search-form"><el-input v-model="dataForm.position" class="input-t" size="small" clearable placeholder="请搜索职位" /></el-form-item>
        <el-form-item label="状态：" prop="apiType" class="search-form">
          <el-select v-model="dataForm.isStop" placeholder="" size="small" style="width:200px">
            <el-option label="启用" :value="false">启用</el-option>
            <el-option label="停用" :value="true">停用</el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" size="small" class="search-btn" @click="searchForm()">查询</el-button>
      </el-form>
    </div> -->
    <!-- <div class="add-line"><el-button type="primary" size="mini" @click="addOrUpdateHandle()">+ 新增</el-button></div> -->
    <div class="tableList">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="linkmanName" label="联系人" />
        <el-table-column prop="phoneNumber" label="联系电话" />
        <!-- <el-table-column prop="enterpriseName" label="企业名称" /> -->
        <el-table-column prop="goodsName" label="货物名称" />
        <el-table-column prop="goodsWeight" label="数量(吨)" />
        <el-table-column prop="distance" label="距离(公里)" />
        <el-table-column prop="merName" label="地址全称" />
        <el-table-column prop="isTaxpayer" label="是否为一般纳税人" width="130">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isTaxpayer === true ? 'success' : 'danger'">{{ scope.row.isTaxpayer ? '是' : '否' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isPlatformUser" label="是否使用过网络货运平台" width="170">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isPlatformUser === true ? 'success' : 'danger'">{{ scope.row.isPlatformUser ? '是' : '否' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间" width="160" />
      </el-table>
      <div class="page-pagination">
        <el-pagination
          background
          :current-page="currentPage"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
          :page-sizes="[10, 20, 50, 100, 300, 500, 1000]"
          :page-size="pageSize"
          prev-text="<"
          next-text=">"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <addJoin ref="addOrUpdate" @refreshDataList="getDataList" />
  </div>
</template>

<script>
export default {
  name: 'EnterpriseList',
  components: {
    addJoin: () => import('@/components/vbackstage/join/addJoin.vue')
  },
  data() {
    return {
      msg: '加入我们',
      dataForm: {
        linkmanName: '',
        phoneNumber: '',
        enterpriseName: '',
        goodsName: '',
        areaId: '', //
        isTaxpayer: '',
        isPlatformUser: ''
      },
      params: {
        pageIndex: 1,
        pageSize: 10,
        orderBy: '',
        sort: ''
      },
      total: 0,
      currentPage: 1,
      tableData: [],
      pageSize: 10
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    filterTag(value, row) {
      return row.tag === value
    },
    // 启用
    enableHandle(id) {
      this.$confirm('是否启用?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.$http.post(`api/Job/OpenJob?jobId=${id}`, {}).then((data) => {
          if (data && data.code === 1) {
            this.$message({ message: '启用成功', type: 'success', duration: 700 })
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {
        console.log('取消')
      })
    },
    // 停用
    deactivateHandle(id) {
      this.$confirm('是否停用?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.$http.post(`api/Job/StopJob?jobId=${id}`, {}).then((data) => {
          if (data && data.code === 1) {
            this.$message({ message: '停用成功', type: 'success', duration: 700 })
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {
        console.log('取消')
      })
    },
    // 新增 / 修改
    addOrUpdateHandle(data) {
      this.$refs.addOrUpdate.open(data)
    },
    getDataList() {
      const obj = Object.assign({}, this.params, this.dataForm)
      this.$http.post('api/ContactUs/GetList', obj).then(res => {
        if (res.code === 1) {
          this.tableData = res.data.dataSource
          this.total = res.data.totalCount
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    searchForm() {
      this.currentPage = 1
      this.params.pageIndex = 1
      this.getDataList()
    },
    pageChange(data) {
      this.currentPage = data
      this.params.pageIndex = data
      this.getDataList()
    },
    handleSizeChange(data) {
      this.params.pageSize = data
      this.searchForm()
    }

  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/backstage.scss";

</style>
